import { __awaiter } from "tslib";
export default function contextMenuActivator(node, activator) {
    const handleContextMenu = (e) => __awaiter(this, void 0, void 0, function* () {
        activator.activate();
    });
    node.addEventListener('contextmenu', handleContextMenu);
    return {
        destroy() {
            node.removeEventListener('contextmenu', handleContextMenu);
        },
    };
}
